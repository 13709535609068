import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { withStore } from '../store';
import { db } from '../db';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericForm from '../components/GenericForm';
import MessageDialog from '../components/MessageDialog';
import { Button } from '@mui/material';
import Translate from '../components/Translate';
import { LOGO_BASE_URL } from '../constants';

const initialFrm = [
  {
    id: 'name',
    type: 'text',
    label: 'Naziv',
    value: '',
    valication: {
      required: true,
    },
  },
  {
    id: 'vat',
    type: 'text',
    label: 'Poreski identifikacioni broj',
    value: '',
    valication: {
      required: true,
    },
  },
  {
    id: 'address',
    type: 'text',
    label: 'Adresa',
    value: '',
    valication: {
    },
  },
  {
    id: 'place',
    type: 'text',
    label: 'Mesto',
    value: '',
    valication: {
    },
  },
  {
    id: 'tel',
    type: 'text',
    label: 'Telefon',
    value: '',
    valication: {
    },
  },
  {
    id: 'salary_description',
    type: 'textarea',
    label: 'Plata opis',
    value: '',
    valication: {
    },
  },
]

const makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const CompanyEdit = (props) => {
  const navigate = useNavigate();

  const [frm, setFrm] = useState(initialFrm);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [logo, setLogo] = useState(null);

  const [taxFrm, setTaxFrm] = useState([]);

  const logoRef = useRef(null);

  const fetchAdditionalColumns = async () => {
    const res = await db.from('company_additional_columns')
      .select();

    if (res.error) {
      console.log(res.error);
      return;
    }

    if (!res.data || !res.data.length) {
      return;
    }


    const resCopmany = await db.from('companies')
      .select()
      .eq('users_id', props.session.user.id);
    const companyId = resCopmany.data[0].id;

    for (const el of res.data) {
      const res2 = await db.from('company_additional_data')
        .select()
        .eq('companies_id', companyId)
        .eq('columns_id', el.id);

      if (!res2.data || !res2.data.length) {
        await db.from('company_additional_data')
          .insert({
            users_id: props.session.user.id,
            companies_id: companyId,
            columns_id: el.id,
            value: '',
          });
      }
    }

    setFrm(_ => {
      const newFields = res.data.map((el, idx) => {
        return {
          id: 'additional_' + idx,
          type: 'text',
          label: el.name,
          value: '',
          valication: {
          },
          metadata: {
            id: el.id,
          }
        }
      });

      return [
        ...initialFrm,
        ...newFields,
      ]
    });
  }

  const fetchTaxes = async () => {
    const id = props.session.user.id;

    const companiesRes = await db.from('companies')
      .select()
      .eq('users_id', id);

    if (companiesRes.error || !companiesRes.data || !companiesRes.data.length) {
      console.log(companiesRes.error);
      return;
    }

    const companyId = companiesRes.data[0].id;

    const res = await db.from('taxes')
      .select()
      .eq('user_can_change', true)
      .order('journal_code');

    if (res.error || !res.data || !res.data.length) {
      console.log(res.error);
      return;
    }

    const elements = [];
    for (const item of res.data) {
      const res2 = await db.from('company_taxes')
        .select()
        .eq('users_id', id)
        .eq('taxes_id', item.id);

      if (res2.error || !res2.data || !res2.data.length) {
        const res3 = await db.from('company_taxes')
          .insert({
            users_id: id,
            companies_id: companyId,
            taxes_id: item.id,
            value: item.value,
          })
          .select();

        if (res3.data && res3.data.length) {
          elements.push(res3.data[0]);
        }
        continue;
      }

      elements.push(res2.data[0]);
    }

    setTaxFrm(elements.map(el => {
      return {
        id: el.id,
        type: 'number',
        label: res.data.find(e => el.taxes_id === e.id).description_de,
        value: el.value,
        valication: {
        },
        metadata: el,
      };
    }));
  }

  const fetchData = async () => {
    const id = props.session.user.id;

    await fetchAdditionalColumns();

    const res = await db.from('companies')
      .select()
      .eq('users_id', id);

    if (res.error) {
      console.log(res.error);
      navigate('/');
    }

    if (!res.data || !res.data.length) {
      navigate('/');
      return;
    }

    const res2 = await db.from('company_additional_data')
      .select()
      .eq('companies_id', res.data[0].id);

    setFrm(oldFrm => {
      const updatedFrm = oldFrm.map(el => {
        let value = res.data[0][el.id];
        if (el.metadata) {
          value = res2.data.find(e => e.columns_id === el.metadata.id).value;
        }
        return {
          ...el,
          value: value,
        }
      });
      return updatedFrm;
    });

    setLogo(res.data[0].logo);

    await fetchTaxes();
  }

  useEffect(() => {
    fetchData().catch(err => console.log(err));
  }, []);

  const onSaveHandler = async (data) => {
    const req = data.filter(el => !el.metadata).reduce((acc, cur) => {
      acc[cur.id] = cur.value;
      return acc;
    }, {});

    const id = props.session.user.id;

    const res = await db.from('companies')
      .update(req)
      .eq('users_id', id);

    if (res.error) {
      console.log(res.error);
      setError(res.error.message);
    }

    for (const el of data) {
      if (!el.metadata) {
        continue;
      }

      const res2 = await db.from('company_additional_data')
        .update({
          value: el.value,
        })
        .eq('users_id', id)
        .eq('columns_id', el.metadata.id);
    }

    setSuccess('Changes saved!');
  }

  const onTaxSaveHandler = async (data) => {
    for (const el of data) {
      const res = await db.from('company_taxes')
        .update({
          value: el.value,
        })
        .eq('id', el.id);

      if (res.error) {
        setError(res.error);
        continue;
      }

      if (!res.data || !res.data.length) {
        continue;
      }
    }

    setSuccess('Changes saved!');
  }

  const onErrorHandler = (err) => {
    setError(err);
  }

  const onLogoUploadHandler = async (event) => {
    if (!logoRef.current.files.length) {
      return;
    }

    const file = logoRef.current.files[0];

    const res = await db.storage.from('logo')
      .upload(`${props.session.user.id}/logo-${makeid(5)}.${file.type.replace(/(.*)\//g, '')}`, file);

    if (res.error) {
      console.log(res.error)
    }

    const newLogo = res.data.path;

    if (logo) {
      const res = await db.storage.from('logo')
        .remove(logo);
    }

    const res2 = await db.from('companies')
      .update({
        logo: newLogo,
      })
      .eq('users_id', props.session.user.id);

    if (res2.error) {
      console.log(res2.error);
    }

    setLogo(newLogo);
  }

  return (
    <Layout title="Podaci o firmi">
      <CCard title=" " back={() => navigate(-1)}>
        {logo ? <img src={`${LOGO_BASE_URL}/${logo}`} style={{ maxHeight: 200 }} /> : null}
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => logoRef.current.click()}><Translate>Promeni logo</Translate></Button>
        <input
          ref={logoRef}
          onChange={onLogoUploadHandler}
          type="file" accept="image/png image/jpeg"
          style={{ display: 'none' }} />
        <br />
        <br />

        <GenericForm
          data={frm}
          onSave={onSaveHandler}
          onError={onErrorHandler} />

        <MessageDialog
          open={!!error}
          title="Greška"
          text={error}
          onClose={() => setError(null)} />

        <MessageDialog
          open={!!success}
          title="Success"
          text={success}
          onClose={() => setSuccess(null)} />
      </CCard>

      <br />

      <CCard title="Odbici">
        <GenericForm
          data={taxFrm}
          onSave={onTaxSaveHandler}
          onError={onErrorHandler} />
      </CCard>
    </Layout>
  );
}

export default withStore(['nav'])(CompanyEdit);
