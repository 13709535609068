import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  CheckBox as CheckBoxIcon,
  IndeterminateCheckBox as CheckBoxFalseIcon,
} from '@mui/icons-material';

import { withStore } from '../store';
import { db } from '../db';
import { translate } from '../utils/translate';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericTable from '../components/GenericTable';
import Translate from '../components/Translate';

const columns = [
  {
    id: 'actions',
    title: translate(''),
  },
  {
    id: 'name',
    title: translate('Ime'),
  },
  {
    id: 'surname',
    title: translate('Prezime'),
  },
  {
    id: 'ahv_number',
    title: translate('AHV'),
  },
  {
    id: 'active',
    title: translate('Active'),
  },
]


const MonthlyEmployees = (props) => {
  const navigate = useNavigate();

  const [values, setValues] = useState([]);

  const [departmentId, setDepartmentId] = useState('all');
  const [departments, setDepartments] = useState([]);

  const [showAll, setShowAll] = useState(false)

  const fetchDepartments = async () => {
    const res = await db.from('departments')
      .select();

    if (res.error) {
      console.log(res.error);
      return;
    }

    setDepartments(res.data);
  }

  const fetchData = async () => {
    let res
    if (showAll) {
      res = await db.from('employees')
        .select()
        .eq('users_id', props.session.user.id)
        .order('surname')
        .order('name');
    } else {
      res = await db.from('employees')
        .select()
        .eq('users_id', props.session.user.id)
        .is('employment_to', null)
        .order('surname')
        .order('name');
    }

    if (res.error) {
      console.log(res.error);
      return;
    }

    setValues(res.data);
  }

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));

    fetchDepartments()
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));
  }, [showAll])

  const onPerHourClicked = (id) => {
    navigate('/salary/monthly/' + id);
  }

  const onHistoryClicked = (id) => {
    navigate(`/salary/history/${id}`);
  }

  const departmentOptions = departments.map(el => {
    return (
      <MenuItem value={el.id}>{el.department}</MenuItem>
    );
  });

  const data = values
    .filter(el => departmentId === 'all' || el.department === departmentId)
    .map(el => {
      const res = {
        ...el,
        active: (
          !el.employment_to ? <CheckBoxIcon color="success" /> : <CheckBoxFalseIcon color="error" />
        ),
        actions: (
          <>
            <Button onClick={() => onPerHourClicked(el.id)}>
              <Translate>Obracun</Translate>
            </Button>
            <Button onClick={() => onHistoryClicked(el.id)}>
              <Translate>Istorija plata</Translate>
            </Button>
          </>
        ),
      };

      return res;
    });

  return (
    <Layout title="Mesecni obracun">
      <CCard title=" " back={() => navigate(-1)}>
        <FormControl fullWidth>
          <InputLabel id="departments-lbl"><Translate>Odeljenje</Translate></InputLabel>
          <Select
            labelId="departments-lbl"
            id="departments"
            label={translate('Odeljenje')}
            value={departmentId}
            onChange={(e) => setDepartmentId(e.target.value)}>
            <MenuItem value="all"><Translate>Sva odeljenja</Translate></MenuItem>
            {departmentOptions}
          </Select>
        </FormControl>
        <br />
        <br />
        <div>
          <FormControlLabel
            control={<Switch checked={showAll} onChange={e => setShowAll(e.target.checked)} />}
            label={translate('Prikaži sve radnike')} />
        </div>
        <br />
        <br />
        <GenericTable
          columns={columns}
          data={data} />
      </CCard>
    </Layout>
  );
}

export default withStore(['nav'])(MonthlyEmployees);
