import React, { useState, useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { db } from './db';

import Home from './pages/Home';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import Departments from './pages/Departments';
import DepartmentEdit from './pages/DepartmentEdit';
import Employees from './pages/Employees';
import EmployeeEdit from './pages/EmployeeEdit';
import CompanyEdit from './pages/CompanyEdit';
import Settings from './pages/Settings';
import ChangePassword from './pages/ChangePassword';
import MonthlyEmployees from './pages/MonthlyEmployees';
import MonthlyCalc from './pages/MonthlyCalc';
import AnnualEmployees from './pages/AnnualEmployees';
import JournalEmployees from './pages/JournalEmployees';
import SalaryHistory from './pages/SalaryHistory';
import { withStore } from './store';

import "/node_modules/flag-icons/css/flag-icons.min.css";

const App = (props) => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    db.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    })

    db.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    })
  }, []);

  useEffect(() => {
    if (!session || !session.user) {
      return;
    }

    fetchLanguages().catch(err => console.log(err));
  }, [session]);

  const fetchLanguages = async () => {
    const res = await db.from('profiles')
      .select()
      .eq('id', session.user.id);

    if (res.error || !res.data || !res.data.length) {
      console.log(res.error);
    }

    const lang = res.data[0].lang;

    const res2 = await db.from('translations')
      .select();

    if (res2.error || !res2.data || !res2.data.length) {
      console.log(res2.error);
    }

    const translations = {};
    res2.data.forEach(el => {
      translations[el.rs] = {
        de: el.de,
        fr: el.fr,
        it: el.it,
      }
    });

    props.nav.setLang(lang);
    props.nav.setTranslations(translations);
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home session={session} />,
    },

    {
      path: '/auth/login',
      element: <Login />,
    },
    {
      path: '/auth/register',
      element: <Register />,
    },

    {
      path: '/departments/new',
      element: <DepartmentEdit session={session} />,
    },
    {
      path: '/departments/:id',
      element: <DepartmentEdit session={session} />,
    },
    {
      path: '/departments',
      element: <Departments session={session} />,
    },

    {
      path: '/employees/new',
      element: <EmployeeEdit session={session} />,
    },
    {
      path: '/employees/:id',
      element: <EmployeeEdit session={session} />,
    },
    {
      path: '/employees',
      element: <Employees session={session} />,
    },

    {
      path: '/company',
      element: <CompanyEdit session={session} />,
    },

    {
      path: '/settings',
      element: <Settings session={session} />,
    },

    {
      path: '/password',
      element: <ChangePassword session={session} />,
    },

    {
      path: '/salary/monthly',
      element: <MonthlyEmployees session={session} />,
    },
    {
      path: '/salary/monthly/:id',
      element: <MonthlyCalc session={session} />,
    },

    {
      path: '/salary/annual',
      element: <AnnualEmployees session={session} />,
    },

    {
      path: '/salary/journal',
      element: <JournalEmployees session={session} />,
    },
    {
      path: '/salary/history/:id',
      element: <SalaryHistory session={session} />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default withStore(['nav'])(App);
