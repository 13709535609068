export const properRound = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const properFormat = (num, decimals = 2) => {
  return properRound(num).toLocaleString('de-CH', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
}

export const formatedToNumber = (str) => {
  if (typeof (str) !== 'string') {
    return str;
  }

  return +str.replaceAll("'", "").replaceAll('’', '').replaceAll('`', '');
}
