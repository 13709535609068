import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { withStore } from '../store';
import { db } from '../db';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericForm from '../components/GenericForm';
import MessageDialog from '../components/MessageDialog';
import { Button } from '@mui/material';
import Translate from '../components/Translate';

const initialFrm = [
  {
    id: 'lang',
    type: 'select',
    label: 'Jezik',
    value: 'de',
    valication: {
    },
    options: [
      {
        title: <><span className="fi fi-rs"></span> &nbsp;&nbsp; Srpski</>,
        value: 'rs',
      },
      {
        title: <><span className="fi fi-de"></span> &nbsp;&nbsp; Deutsch</>,
        value: 'de',
      },
      {
        title: <><span className="fi fi-fr"></span> &nbsp;&nbsp; Français</>,
        value: 'fr',
      },
      {
        title: <><span className="fi fi-it"></span> &nbsp;&nbsp; Italiano</>,
        value: 'it',
      },
    ]
  },
]

const initialFrmPassword = [
  {
    id: 'password',
    type: 'password',
    label: 'New password',
    value: '',
    valication: {
    },
  },
  {
    id: 'password2',
    type: 'password',
    label: 'Repeat password',
    value: '',
    valication: {
    },
  },
]

const CompanyEdit = (props) => {
  const navigate = useNavigate();

  const [frm, setFrm] = useState(initialFrm);
  const [frmPassword, setFrmPassword] = useState(initialFrmPassword);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const fetchData = async () => {
    const id = props.session.user.id;

    const res = await db.from('profiles')
      .select()
      .eq('id', id);

    if (res.error) {
      console.log(res.error);
      navigate('/');
    }

    if (res.data && res.data.length) {
      setFrm(oldFrm => {
        const updatedFrm = oldFrm.map(el => ({
          ...el,
          value: res.data[0][el.id],
        }));
        return updatedFrm;
      });
    }
  }

  useEffect(() => {
    fetchData().catch(err => console.log(err));
  }, []);

  const onSaveHandler = async (data) => {
    const req = data.reduce((acc, cur) => {
      acc[cur.id] = cur.value;
      return acc;
    }, {});

    const id = props.session.user.id;

    const res = await db.from('profiles')
      .update(req)
      .eq('id', id);

    if (res.error) {
      console.log(res.error);
      setError(res.error.message);
    }

    props.nav.setLang(req.lang);
    setSuccess('Changes saved!');
  }

  const onSaveHandlerPassword = async (data) => {
    if (data.length < 2) {
      return;
    }

    const password = data[0].value;
    const password2 = data[1].value;

    if (!password || password.length < 8) {
      setError('Lozinka mora imati najvanje 8 karaktera');
      return;
    }

    if (password !== password2) {
      setError('Lozinke nisu iste');
      return;
    }

    const res = await db.auth.updateUser({
      password: password,
    });

    if (res.error) {
      console.log(res.error);
      setError(res.error.message);
    }

    setSuccess('Changes saved!');
  }

  const onErrorHandler = (err) => {
    setError(err);
  }

  return (
    <Layout title="Lične postavke">
      <CCard title=" " back={() => navigate(-1)}>
        <GenericForm
          data={frm}
          onSave={onSaveHandler}
          onError={onErrorHandler} />
      </CCard>

      <br />

      <CCard>
        <GenericForm
          data={frmPassword}
          onSave={onSaveHandlerPassword}
          onError={onErrorHandler} />
      </CCard>

      <MessageDialog
        open={!!error}
        title="Greška"
        text={error}
        onClose={() => setError(null)} />

      <MessageDialog
        open={!!success}
        title="Success"
        text={success}
        onClose={() => setSuccess(null)} />
    </Layout>
  );
}

export default withStore(['nav'])(CompanyEdit);
