import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { translate } from '../utils/translate';
import Translate from '../components/Translate';

const GenericForm = (props) => {
  const [values, setValues] = useState([]);

  const {
    data,
  } = props;

  useEffect(() => {
    if (data) {
      setValues([...data]);
    }
  }, [data]);

  const showError = (msg) => {
    if (props.onError) {
      props.onError(msg);
    }
  }

  const validate = () => {
    for (const el of values) {
      if (el.validation) {
        if (el.validation.required) {
          if (!el.value.length) {
            showError(translate(el.label) + ' is required!');
            return false;
          }
        }
        if (el.validation.min) {
          if (el.type === 'number') {
            if (el.value < el.validation.min) {
              showError(translate(el.label) + ' must be at greater than ' + el.validation.min + '!');
              return false;
            }
          } else {
            if (el.value.length < el.validation.min) {
              showError(translate(el.label) + ' must be at least ' + el.validation.min + ' characters long!');
              return false;
            }
          }
        }
        if (el.validation.max) {
          if (el.type === 'number') {
            if (el.value > el.validation.max) {
              showError(translate(el.label) + ' must be at greater than ' + el.validation.min + '!');
              return false;
            }
          } else {
            if (el.value.length > el.validation.max) {
              showError(translate(el.label) + ' must be at least ' + el.validation.min + ' characters long!');
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  const onSave = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }

    if (props.onSave) {
      props.onSave(values);
    }
  }

  const onChangeHandler = (event, id, type, newValue = undefined) => {
    let value;
    if (event) {
      value = event.target.value;
    }

    if (type === 'switch') {
      value = event.target.checked;
    }

    if (newValue) {
      value = newValue;
    }

    const index = values.findIndex(el => el.id === id);
    if (index < 0) {
      return;
    }

    const updatedArray = [...values];
    const updatedData = {
      ...values[index],
      value: value,
    };
    updatedArray[index] = updatedData;
    setValues(updatedArray);
  }

  const components = values.map(el => {
    let cmp = null;

    if (el.type === 'text') {
      cmp = (
        <TextField
          fullWidth
          id={el.id}
          label={translate(el.label)}
          variant="standard"
          value={el.value}
          onChange={e => onChangeHandler(e, el.id, el.type)}
          inputProps={{
            readOnly: el.readonly,
          }} />
      );
    } else if (el.type === 'textarea') {
      cmp = (
        <TextField
          fullWidth
          id={el.id}
          label={translate(el.label)}
          variant="standard"
          value={el.value}
          onChange={e => onChangeHandler(e, el.id, el.type)}
          multiline
          minRows={3}
          inputProps={{
            readOnly: el.readonly,
          }} />
      );
    } else if (el.type === 'password') {
      cmp = (
        <TextField
          fullWidth
          id={el.id}
          label={translate(el.label)}
          type="password"
          variant="standard"
          value={el.value}
          onChange={e => onChangeHandler(e, el.id, el.type)}
          inputProps={{
            readOnly: el.readonly,
          }} />
      );
    } else if (el.type === 'number') {
      cmp = (
        <TextField
          fullWidth
          id={el.id}
          label={translate(el.label)}
          type="number"
          variant="standard"
          value={el.value}
          onChange={e => onChangeHandler(e, el.id, el.type)} />
      );
    } else if (el.type === 'autocomplete') {
      cmp = (
        <Autocomplete
          id={el.id}
          freeSolo
          options={el.options.map((option) => el.translateOptions ? translate(option.title) : option.title)}
          value={el.value}
          onChange={(event, newValue) => onChangeHandler(event, el.id, 'autocomplete', newValue)}
          renderInput={(params) => <TextField {...params} label={translate(el.label)} />}
        />
      );
    } else if (el.type === 'select') {
      cmp = (
        <FormControl variant="standard">
          <InputLabel id={el.id + '-label'}>{translate(el.label)}</InputLabel>
          <Select
            labelId={el.id + '-label'}
            id={el.id}
            value={el.value}
            label={translate(el.label)}
            onChange={e => onChangeHandler(e, el.id, el.type)}>
            {el.options.map(opt => {
              return (
                <MenuItem value={opt.value} key={opt.value}>{el.translateOptions ? translate(opt.title) : opt.title}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    } else if (el.type === 'switch') {
      cmp = (
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={el.value} onChange={e => onChangeHandler(e, el.id, el.type)} />}
            label={translate(el.label)} />
        </FormGroup>
      );
    } else if (el.type === 'date') {
      cmp = (
        <LocalizationProvider dateAdapter={AdapterDayjs} variant="standard">
          <DatePicker label={translate(el.label)} value={el.value} onChange={newValue => onChangeHandler(undefined, el.id, el.type, newValue)} format="DD.MM.YYYY." />
        </LocalizationProvider>
      );
    }

    return (
      <Grid item xs={12} key={el.id}>
        <FormControl fullWidth>
          {cmp}
        </FormControl>
      </Grid>
    );
  });

  return (
    <>
      <Grid container spacing={1}>
        {components}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}>
            <Translate>Save</Translate>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default GenericForm;
