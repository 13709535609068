import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

import { withStore } from '../store';
import { db } from '../db';
import { translate } from '../utils/translate';
import Translate from '../components/Translate';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericTable from '../components/GenericTable';

const columns = [
  {
    id: 'name',
    title: translate('Ime'),
  },
  {
    id: 'surname',
    title: translate('Prezime'),
  },
  {
    id: 'actions',
    title: '',
  },
]


const AnnualEmployees = (props) => {
  const navigate = useNavigate();

  const [values, setValues] = useState([]);

  const [departmentId, setDepartmentId] = useState('all');
  const [departments, setDepartments] = useState([]);

  const fetchDepartments = async () => {
    const res = await db.from('departments')
      .select();

    if (res.error) {
      console.log(res.error);
      return;
    }

    setDepartments(res.data);
  }

  const fetchData = async () => {
    const res = await db.from('employees')
      .select()
      .eq('users_id', props.session.user.id)
      .order('surname')
      .order('name');

    if (res.error) {
      console.log(res.error);
      return;
    }

    setValues(res.data);
  }

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));

    fetchDepartments()
      .catch(err => console.log(err));
  }, []);

  const onCalculateAnnual = (id) => {
    // TODO: Show annual salary page
  }

  const departmentOptions = departments.map(el => {
    return (
      <MenuItem value={el.id}>{el.department}</MenuItem>
    );
  });

  const data = values
    .filter(el => departmentId === 'all' || el.department === departmentId)
    .map(el => {
      const res = {
        ...el,
        actions: (
          <>
            <Button onClick={() => onCalculateAnnual(el.id)}>
              <Translate>Obracunaj</Translate>
            </Button>
          </>
        ),
      };

      return res;
    });

  return (
    <Layout title="Godisnji obracun">
      <CCard>
        <FormControl fullWidth>
          <InputLabel id="departments-lbl">Odeljenje</InputLabel>
          <Select
            labelId="departments-lbl"
            id="departments"
            label={translate('Odeljenje')}
            value={departmentId}
            onChange={(e) => setDepartmentId(e.target.value)}>
            <MenuItem value="all"><Translate>Sva odeljenja</Translate></MenuItem>
            {departmentOptions}
          </Select>
        </FormControl>
        <br />
        <br />
        <GenericTable
          columns={columns}
          data={data} />
      </CCard>
    </Layout>
  );
}

export default withStore(['nav'])(AnnualEmployees);
