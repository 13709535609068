import React from 'react';
import { translate } from '../utils/translate';

const Translate = (props) => {
  return (
    <>{translate(props.children)}</>
  );
}

export default Translate;
