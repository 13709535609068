import React, { useState } from 'react';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

import { withStore } from '../store';
import { translate } from '../utils/translate';
import Translate from '../components/Translate';

const Layout = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { menuItems } = props

  const menuItemsDisplay = menuItems.map((el, index) => (
    <React.Fragment key={index}>
      <List>
        {
          el.map((el2) => (
            <Link to={el2.link} key={el2.title} style={{ color: 'black', textDecoration: 'none' }}>
              <ListItem key={el2.title}>
                {el2.icon ? <ListItemIcon>{el2.icon}</ListItemIcon> : null}
                <ListItemText primary={el2.title} />
              </ListItem>
            </Link>
          ))
        }
      </List>
      <Divider />
    </React.Fragment>
  ));

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setIsDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Translate>{props.title}</Translate>
            </Typography>
            {/*<Button color="inherit">Login</Button>*/}
          </Toolbar>
        </AppBar>
      </Box>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setIsDrawerOpen(false)}
          onKeyDown={() => setIsDrawerOpen(false)}
        >
          {menuItemsDisplay}
        </Box>
      </Drawer>

      <Box sx={{ flexGorw: 1, padding: 3 }}>
        {props.children}
      </Box >
    </>
  );
}

export default withStore(['nav'])(Layout);
