import React, { useState, useEffect } from 'react';
import {
  Home as HomeIcon,
  People as PeopleIcon,
  Factory as FactoryIcon,
  Money as MoneyIcon,
  AccountBalance as AccountBalanceIcon,
  History as HistoryIcon,
  Business as BusinessIcon,
  Settings as SettingsIcon,
  Key as KeyIcon,
  Payment as PaymentIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';

import { withStore } from '../store';
import { translate } from '../utils/translate';

import DesktopLayout from './DesktopLayout.js';
import MobileLayout from './MobileLayout.js';

export const createMenuItems = () => {
  return [
    [
      {
        title: translate('Početna'),
        icon: <HomeIcon />,
        link: '/',
      },
    ],
    [
      {
        title: translate('Radnici'),
        icon: <PeopleIcon />,
        link: '/employees',
      },
      {
        title: translate('Odeljenja'),
        icon: <FactoryIcon />,
        link: '/departments',
      },
    ],
    [
      {
        title: translate('Mesečni obračun'),
        icon: <MoneyIcon />,
        link: '/salary/monthly',
      },
      {
        title: translate('Godišnji obračun'),
        icon: <AccountBalanceIcon />,
        link: '/salary/annual',
      },
      {
        title: translate('Žurnal'),
        icon: <HistoryIcon />,
        link: '/salary/journal',
      }
    ],
    [
      {
        title: translate('Podaci o firmi'),
        icon: <BusinessIcon />,
        link: '/company',
      },
      {
        title: translate('Lične postavke'),
        icon: <SettingsIcon />,
        link: '/settings',
      },
      {
        title: translate('Plaćanje'),
        icon: <PaymentIcon />,
        link: 'billing',
      },
    ],
    [
      {
        title: translate('Odjavi se'),
        icon: <LogoutIcon />,
        link: '/logout',
      },
    ],
  ];
}

const Layout = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const menuItems = createMenuItems();

  if (isMobile) {
    return <MobileLayout menuItems={menuItems} title={translate(props.title)}>{props.children}</MobileLayout>
  }

  return <DesktopLayout menuItems={menuItems} title={translate(props.title)}>{props.children}</DesktopLayout>
}

export default withStore(['nav'])(Layout);
