import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { withStore } from '../store';
import { db } from '../db';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericForm from '../components/GenericForm';
import MessageDialog from '../components/MessageDialog';

const initialFrm = [
  {
    id: 'password',
    type: 'password',
    label: 'New password',
    value: '',
    valication: {
    },
  },
  {
    id: 'password2',
    type: 'password',
    label: 'Repeat password',
    value: '',
    valication: {
    },
  },
]

const ChangePassword = (props) => {
  const navigate = useNavigate();

  const [frm, setFrm] = useState(initialFrm);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
  }, []);

  const onSaveHandler = async (data) => {
    if (data.length < 2) {
      return;
    }

    const password = data[0].value;
    const password2 = data[1].value;

    if (!password || password.length < 8) {
      setError('Lozinka mora imati najvanje 8 karaktera');
      return;
    }

    if (password !== password2) {
      setError('Lozinke nisu iste');
      return;
    }

    const res = await db.auth.updateUser({
      password: password,
    });

    if (res.error) {
      console.log(res.error);
      setError(res.error.message);
    }

    setSuccess('Changes saved!');
  }

  const onErrorHandler = (err) => {
    setError(err);
  }

  return (
    <Layout title="Promena lozinke">
      <CCard title=" " back={() => navigate(-1)}>
        <GenericForm
          data={frm}
          onSave={onSaveHandler}
          onError={onErrorHandler} />

        <MessageDialog
          open={!!error}
          title="Greška"
          text={error}
          onClose={() => setError(null)} />

        <MessageDialog
          open={!!success}
          title="Success"
          text={success}
          onClose={() => setSuccess(null)} />
      </CCard>
    </Layout>
  );
}

export default withStore(['nav'])(ChangePassword);
