import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IconButton,
  Grid,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';

import { withStore } from '../store';
import { db } from '../db';
import { translate } from '../utils/translate';
import { generatePassword } from '../utils/random';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericForm from '../components/GenericForm';
import MessageDialog from '../components/MessageDialog';
import ConfirmDialog from '../components/ConfirmDialog';

const initialFrm = [
  {
    id: 'department',
    type: 'select',
    label: 'Odeljenje',
    value: '',
    valication: {
      required: true,
    },
    options: [],
  },
  {
    id: 'email',
    type: 'text',
    label: 'Email',
    value: '',
    valication: {
      required: true,
    },
    readonly: false,
  },
  {
    id: 'password',
    type: 'text',
    label: 'Password',
    value: '',
    valication: {
      required: true,
    },
    readonly: true,
  },
  {
    id: 'name',
    type: 'text',
    label: 'Ime',
    value: '',
    valication: {
      required: true,
    },
  },
  {
    id: 'surname',
    type: 'text',
    label: 'Prezime',
    value: '',
    valication: {
      required: true,
    },
  },
  {
    id: 'birthdate',
    type: 'date',
    label: 'Datum rodjenja',
    value: '',
    valication: {
    },
  },
  {
    id: 'county',
    type: 'select',
    label: 'Kanton',
    value: '',
    valication: {
      required: true,
    },
    options: [
      { value: 'AG', title: 'AG' },
      { value: 'AI', title: 'AI' },
      { value: 'AR', title: 'AR' },
      { value: 'BE', title: 'BE' },
      { value: 'BL', title: 'BL' },
      { value: 'BS', title: 'BS' },
      { value: 'FR', title: 'FR' },
      { value: 'GE', title: 'GE' },
      { value: 'GL', title: 'GL' },
      { value: 'GR', title: 'GR' },
      { value: 'JU', title: 'JU' },
      { value: 'LU', title: 'LU' },
      { value: 'NE', title: 'NE' },
      { value: 'NW', title: 'NW' },
      { value: 'OW', title: 'OW' },
      { value: 'SG', title: 'SG' },
      { value: 'SH', title: 'SH' },
      { value: 'SO', title: 'SO' },
      { value: 'SZ', title: 'SZ' },
      { value: 'TG', title: 'TG' },
      { value: 'TI', title: 'TI' },
      { value: 'UR', title: 'UR' },
      { value: 'VD', title: 'VD' },
      { value: 'VS', title: 'VS' },
      { value: 'ZG', title: 'ZG' },
      { value: 'ZH', title: 'ZH' },

    ]
  },
  {
    id: 'sex',
    type: 'select',
    label: 'Pol',
    value: '',
    valication: {
    },
    options: [
      {
        value: false,
        title: 'Muski',
      },
      {
        value: true,
        title: 'Zenski',
      },
    ],
    translateOptions: true,
  },
  {
    id: 'ahv_number',
    type: 'text',
    label: 'AHV Broj',
    value: '',
    valication: {
    },
  },
  {
    id: 'iban',
    type: 'text',
    label: 'IBAN',
    value: '',
    valication: {
    },
  },
  {
    id: 'bank_name',
    type: 'text',
    label: 'Naziv banke',
    value: '',
    valication: {
    },
  },
  {
    id: 'address',
    type: 'text',
    label: 'Adresa',
    value: '',
    valication: {
    },
  },
  {
    id: 'place',
    type: 'text',
    label: 'Mesto',
    value: '',
    valication: {
    },
  },
  {
    id: 'zip',
    type: 'text',
    label: 'Postanski broj',
    value: '',
    valication: {
    },
  },
  {
    id: 'vocation',
    type: 'text',
    label: 'Zanimanje',
    value: '',
    valication: {
    },
  },
  {
    id: 'employment_from',
    type: 'date',
    label: 'Datum zasnivanja radnog odnosa',
    value: '',
    valication: {
    },
  },
  {
    id: 'employment_to',
    type: 'date',
    label: 'Datum prekida odnosa',
    value: '',
    valication: {
    },
  },
  {
    id: 'has_ahv',
    type: 'switch',
    label: 'AHV Obaveza',
    value: true,
    valication: {
    },
  },
  {
    id: 'has_qs',
    type: 'switch',
    label: 'QS Odbitak',
    value: false,
    valication: {
    },
  },
  {
    id: 'has_bvg',
    type: 'switch',
    label: 'BVG Odbitak',
    value: false,
    valication: {
    },
  },
  {
    id: 'has_child_addition',
    type: 'switch',
    label: 'Dečiji dodatak',
    value: false,
    valication: {
    },
  },
]

const EmployeeEdit = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const isNew = id ? false : true;

  const [frm, setFrm] = useState(initialFrm);
  const [taxFrm, setTaxFrm] = useState([]);
  const [error, setError] = useState(null);

  const [childrens, setChildrens] = useState([]);

  const [removeChildrenIdx, setRemoveChildrenIdx] = useState(null);

  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    const res = await db.from('employees')
      .select()
      .eq('id', id);

    if (res.error) {
      console.log(res.error);
      navigate('/employees');
    }

    if (res.data && res.data.length) {
      setFrm(oldFrm => {
        const updatedFrm = oldFrm.map(el => {
          let value = res.data[0][el.id];
          if (el.type === 'date') {
            value = dayjs(new Date(value));

            if (value.isBefore('1980-01-01')) {
              value = null;
            }
          }
          return {
            ...el,
            value: value,
            reqadonly: el.id === 'password' || el.id === 'email' ? true : false,
          }
        });

        return updatedFrm;
      });
    }

    const res2 = await db.from('childrens')
      .select()
      .eq('employees_id', id);
  }

  const fetchDepartments = async () => {
    const res = await db.from('departments')
      .select();

    if (res.error) {
      console.log(res.error);
      navigate('/employees');
    }

    if (res.data && res.data.length) {
      const options = res.data.map(el => ({
        value: el.id,
        title: el.department,
      }));

      setFrm(oldFrm => {
        const updatedFrm = [...oldFrm];
        const updatedItem = {
          ...oldFrm[0],
          options: options,
        }
        updatedFrm[0] = updatedItem;

        return updatedFrm;
      });
    }
  }

  const fetchChildrens = async () => {
    const res = await db.from('childrens')
      .select()
      .eq('employees_id', id);

    if (res.error) {
      console.log(res.error);
      navigate('/employees');
    }

    if (res.data && res.data.length) {
      setChildrens([
        ...res.data,
      ]);
    }
  }

  const fetchTaxes = async () => {
    const userId = props.session.user.id;

    const companiesRes = await db.from('companies')
      .select()
      .eq('users_id', userId);

    if (companiesRes.error || !companiesRes.data || !companiesRes.data.length) {
      console.log(companiesRes.error);
      return;
    }

    const companyId = companiesRes.data[0].id;

    const res = await db.from('taxes')
      .select()
      .eq('employee_can_change', true)
      .order('journal_code');

    if (res.error || !res.data || !res.data.length) {
      console.log(res.error);
      return;
    }

    const elements = [];
    for (const item of res.data) {
      const res2 = await db.from('employee_taxes')
        .select()
        .eq('users_id', userId)
        .eq('taxes_id', item.id)
        .eq('employees_id', id);

      if (res2.error || !res2.data || !res2.data.length) {
        const res3 = await db.from('employee_taxes')
          .insert({
            users_id: userId,
            companies_id: companyId,
            employees_id: id,
            taxes_id: item.id,
            value: item.value,
          })
          .select();

        if (res3.data && res3.data.length) {
          elements.push(res3.data[0]);
        }
        continue;
      }

      elements.push(res2.data[0]);
    }

    setTaxFrm(elements.map(el => {
      return {
        id: el.id,
        type: 'number',
        label: res.data.find(e => el.taxes_id === e.id).description_de,
        value: el.value,
        valication: {
        },
        metadata: el,
      };
    }));
  }

  useEffect(() => {
    const fn = async () => {
      await fetchDepartments();
      if (id) {
        await fetchData();
        await fetchChildrens();
        await fetchTaxes();
      } else if (props.session) {
        setFrm(oldFrm => {
          const updatedFrm = [
            ...oldFrm,
          ];
          updatedFrm[2] = {
            ...oldFrm[2],
            value: generatePassword(8),
          }
          return updatedFrm;
        });
      }
    }

    fn().catch(err => console.log(err));
  }, [id]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onSaveHandler = async (data) => {
    const req = data.reduce((acc, cur) => {
      acc[cur.id] = cur.value;
      return acc;
    }, {});

    req.users_id = props.session.user.id;

    if (isNew) {
      const res = await db.from('employees')
        .insert(req)
        .select();

      if (res.error) {
        console.log(res.error);
        setError(res.error.message);
        return;
      }

      if (res.data && res.data.length) {
        navigate(`/employees/${res.data[0].id}`);
      }
    } else {
      const res = await db.from('employees')
        .update(req)
        .eq('id', id);

      if (res.error) {
        console.log(res.error);
        setError(res.error.message);
        return;
      }
    }

    setOpen(true);
  }

  const onTaxSaveHandler = async (data) => {
    for (const el of data) {
      const res = await db.from('employee_taxes')
        .update({
          value: el.value,
        })
        .eq('id', el.id);

      if (res.error) {
        setError(res.error);
        continue;
      }

      if (!res.data || !res.data.length) {
        continue;
      }
    }

    setOpen(true);
  }

  const onErrorHandler = (err) => {
    setError(err);
  }

  const onAddChildHandler = () => {
    setChildrens(prev => [
      ...prev,
      {
        name: '',
        allowance: 0,
      }
    ]);
  }

  const onChildFieldUpdate = (idx, key, value) => {
    setChildrens(prev => {

      const updateChild = {
        ...prev[idx],
        [key]: value,
      }

      const updatedArr = [...prev]
      updatedArr[idx] = updateChild;

      return updatedArr;
    });
  }

  const onRemoveChildHandler = async () => {
    if (childrens[removeChildrenIdx].id) {
      const res = await db.from('childrens')
        .delete()
        .eq('id', childrens[removeChildrenIdx].id);
    }

    setChildrens(prev => prev.filter((_, idx) => idx !== removeChildrenIdx));
    setRemoveChildrenIdx(null);
  }

  const onSaveChildshandler = async () => {
    for (let child of childrens) {
      if (child.id) {
        const res = await db.from('childrens')
          .update({
            name: child.name,
            allowance: child.allowance,
          })
          .eq('id', child.id);
      } else {
        const res = await db.from('childrens')
          .insert({
            name: child.name,
            allowance: child.allowance,
            users_id: props.session.user.id,
            employees_id: id,
          });
      }
    }

    await fetchChildrens();

    setOpen(true);
  }

  let childrensCard = null;
  if (!isNew) {
    const childrensComp = childrens.map((el, idx) => {
      return (
        <React.Fragment key={el.id}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Ime"
              variant="standard"
              value={el.name}
              onChange={e => onChildFieldUpdate(idx, 'name', e.target.value)} />
          </Grid >
          <Grid item xs={5}>
            <TextField
              fullWidth
              label="Deciji dodataak"
              variant="standard"
              type="number"
              value={el.allowance}
              onChange={e => onChildFieldUpdate(idx, 'allowance', e.target.value)} />
          </Grid >
          <Grid item xs={1}>
            <IconButton color="error" onClick={() => setRemoveChildrenIdx(idx)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </React.Fragment >
      );
    });

    childrensCard = (
      <CCard title="Deca">
        <Grid container spacing={2}>
          {childrensComp}
          <Grid item xs={12}>
            <IconButton color="primary" onClick={onAddChildHandler}><AddIcon /></IconButton>
          </Grid>
        </Grid>
        <br />
        <Button variant="contained" onClick={onSaveChildshandler}>Sacuvaj decu</Button>

        <ConfirmDialog
          title="Brisanje deteta"
          text="Da li ste sigurni da zelite obbrisati dete"
          open={removeChildrenIdx !== null}
          onConfirm={onRemoveChildHandler}
          onCancel={() => setRemoveChildrenIdx(null)}
          payload={removeChildrenIdx}
          danger
        />
      </CCard>
    );
  }

  return (
    <Layout title="Radnici">
      <CCard title=" " back={() => navigate(-1)}>
        <GenericForm
          data={frm}
          onSave={onSaveHandler}
          onError={onErrorHandler} />

        <MessageDialog
          open={!!error}
          title="Greška"
          text={error}
          onClose={() => setError(null)} />
      </CCard>
      <br />

      <CCard title="Odbici">
        <GenericForm
          data={taxFrm}
          onSave={onTaxSaveHandler}
          onError={onErrorHandler} />
      </CCard>

      <br />

      {childrensCard}

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Izmene uspesno sacuvane
        </Alert>
      </Snackbar>
    </Layout>
  );
}

export default withStore(['nav'])(EmployeeEdit);
