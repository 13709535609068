import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = (props) => {
  const handleClose = (success) => {
    if (success) {
      if (props.onConfirm) {
        props.onConfirm(props.playload);
      }
    } else {
      if (props.onCancel) {
        props.onCancel();
      }
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => handleClose(false)}>
          Ne
        </Button>
        <Button variant="contained" color={props.danger ? 'error' : 'primary'} onClick={() => handleClose(true)} autoFocus>
          Da
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export default ConfirmDialog;
