import React, { useState, useEffect } from 'react';
import { unstable_useBlocker, useNavigate, useParams, } from 'react-router-dom';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  Autocomplete,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { PDFViewer } from '@react-pdf/renderer';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { withStore } from '../store';
import { db } from '../db';
import { properRound, properFormat, formatedToNumber } from '../utils/math';
import { createMonthlyPdf } from '../pdf/MonthlyPdf';
import { translate } from '../utils/translate';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import MessageDialog from '../components/MessageDialog';
import Translate from '../components/Translate';

const MonthlyCalc = (props) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [salaryId, setSalaryId] = useState(null);

  const [employee, setEmployee] = useState({});
  const [salary, setSalary] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [other, setOther] = useState([]);

  const [salaryTotal, setSalaryTotal] = useState(0);
  const [taxesTotal, setTaxesTotal] = useState(0);
  const [otherTotal, setOtherTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [pdf, setPdf] = useState(null);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [date, setDate] = useState(new AdapterDayjs().date(new Date()));
  const [ahv, setAhv] = useState(true);
  const [qs, setQs] = useState(false);
  const [bvg, setBvg] = useState(false);
  const [salaryDescription, setSalaryDescription] = useState('');

  const [salaryItems, setSalaryItems] = useState([]);

  unstable_useBlocker(() => {
    if (pdf) {
      setPdf(null)
      return true
    }

    return false
  })

  const fetchSalaryItems = async () => {
    const res = await db.from('taxes')
      .select()
      .order('journal_code');

    if (res.error) {
      console.log(res.error);
      return;
    }

    if (!res.data || !res.data.length) {
      return;
    }

    const updatedItems = [...res.data];

    let res2 = await db.from('company_taxes')
      .select()
      .eq('users_id', props.session.user.id);

    if (res2.error) {
      console.log(res2.error);
    }

    if (res2.data && res2.data.length) {
      for (const el of updatedItems) {
        const data = res2.data.find(e => e.taxes_id === el.id);
        if (data) {
          if (el.type === 'p') {
            el.value = data.value;
          } else {
            el.total = data.value;
          }
        }
      }
    }

    res2 = await db.from('employee_taxes')
      .select()
      .eq('users_id', props.session.user.id)
      .eq('employees_id', id);

    if (res2.error) {
      console.log(res2.error);
    }

    if (res2.data && res2.data.length) {
      for (const el of updatedItems) {
        const data = res2.data.find(e => e.taxes_id === el.id);
        if (data) {
          if (el.type === 'p') {
            el.value = data.value;
          } else {
            el.total = data.value;
          }
        }
      }
    }

    const res3 = await db.from('childrens')
      .select()
      .eq('employees_id', id)

    if (res3.error) {
      console.log(res2.error);
    }

    if (res3.data && res3.data.length) {
      let allowance = 0;
      for (const child of res3.data) {
        allowance += formatedToNumber(child.allowance)
      }

      const idx = updatedItems.findIndex(el => el.journal_code === 300);
      if (idx >= 0) {
        updatedItems[idx].total = allowance;
      }
    }

    setSalaryItems(updatedItems);
  }

  const fetchData = async () => {
    const res = await db.from('employees')
      .select()
      .eq('id', id);

    if (res.error || !res.data || !res.data.length) {
      console.log(res.error);
      navigate(-1);
      return;
    }

    if (res.data && res.data.length) {
      setEmployee(res.data[0]);

      setAhv(res.data[0].has_ahv);
      setQs(res.data[0].has_qs);
      setBvg(res.data[0].has_bvg);
    }
  }

  const fetchTaxes = async () => {
    const orQuery = [
      'category.eq.default',
    ]
    if (employee.has_ahv) {
      orQuery.push('category.eq.ahv');
    }
    if (employee.has_bvg) {
      orQuery.push('category.eq.bvg');
    }
    if (employee.has_qs) {
      orQuery.push('category.eq.qs');
    }
    const res = await db.from('taxes')
      .select()
      .or(orQuery.join(','))
      .order("journal_code");

    if (res.error) {
      console.log(res.error);
      return;
    }

    if (!res.data || !res.data.length) {
      return;
    }

    const updatedSalary = [];
    const updatedTaxes = [];
    const updatedOther = [];

    const res2 = await db.from('company_taxes')
      .select()
      .eq('users_id', props.session.user.id);

    if (res2.error) {
      console.log(res2.error);
    }

    for (const el of res.data) {
      const elId = el.id
      el.description = el.description_de;
      el.type = el.type ? el.type : 'v';
      delete el.id;
      delete el.created_at;
      delete el.description_de;
      delete el.description_fr;
      delete el.description_it;

      if (el.type === 'p') {
        el.value = properFormat(el.value, 3);
      }

      const data = res2.data.find(e => e.taxes_id === elId);

      if (data) {
        if (el.type === 'p') {
          el.value = properFormat(data.value, 3);
        } else {
          el.total = properFormat(data.value);
        }
      }

      if (el.category == "bvg") {
        el.total = properFormat(employee.bvg_amount);
      }

      if (el.group === 'salary') {
        updatedSalary.push({
          ...el,
        });
      } else if (el.group === 'tax') {
        updatedTaxes.push({
          ...el,
        });
      } else if (el.group === 'other') {
        updatedOther.push({
          ...el,
        });
      }
    }

    calculate(updatedSalary, updatedTaxes, updatedOther);

    const res1 = await db.from('companies')
      .select()
      .eq('users_id', props.session.user.id);

    if (res1.error) {
      console.log(res1.error);
      return;
    }

    if (!res1.data || !res1.data.length) {
      return;
    }

    setSalaryDescription(res1.data[0].salary_description);
  }

  const fetchSalary = async (id, year, month) => {
    setSalaryId(null);

    const res = await db.from('salary_monthly')
      .select()
      .eq('employees_id', id)
      .eq('month', month)
      .eq('year', year);

    if (res.error) {
      console.log(res.error);
      await fetchTaxes();
      return;
    }

    if (!res.data || !res.data.length) {
      await fetchTaxes();
      return;
    }

    setSalaryId(res.data[0].id);
    setAhv(res.data[0].ahv);
    setQs(res.data[0].qs);
    setBvg(res.data[0].bvg);
    setSalaryDescription(res.data[0].salary_description);
    setDate(new AdapterDayjs().date(new Date(res.data[0].date)));

    const res2 = await db.from('salary_monthly_items')
      .select()
      .eq('salary_monthly_id', res.data[0].id)
      .order('journal_code');

    if (res2.error) {
      console.log(res2.error);
      return;
    }

    if (!res.data || !res.data.length) {
      return;
    }

    const updatedSalary = [];
    const updatedTaxes = [];
    const updatedOther = [];

    for (const el of res2.data) {
      if (el.group === 'salary') {
        updatedSalary.push({
          ...el,
          value: properFormat(el.value, el.type === 'p' ? 3 : 2),
          base: properFormat(el.base),
          total: properFormat(el.total),
        });
      } else if (el.group === 'tax') {
        updatedTaxes.push({
          ...el,
          value: properFormat(el.value, el.type === 'p' ? 3 : 2),
          base: properFormat(el.base),
          total: properFormat(el.total),
        });
      } else if (el.group === 'other') {
        updatedOther.push({
          ...el,
          value: properFormat(el.value, el.type === 'p' ? 3 : 2),
          base: properFormat(el.base),
          total: properFormat(el.total),
        });
      }
    }

    calculate(updatedSalary, updatedTaxes, updatedOther);
  }

  useEffect(() => {
    const load = async () => {
      await fetchData();
    }

    if (id) {
      load().catch(err => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    const load = async () => {
      setAhv(employee.has_ahv);
      setQs(employee.has_qs);
      setBvg(employee.has_bvg);
      await fetchSalary(id, year, month);
    }

    load().catch(err => console.log(err));
  }, [employee, year, month]);

  useEffect(() => {
    fetchSalaryItems().catch(err => console.log(err));
  }, []);

  const saveSalaryItem = async (data) => {
    const req = {
      ...data,
      value: formatedToNumber(data.value),
      base: formatedToNumber(data.base),
      total: formatedToNumber(data.total),
      users_id: props.session.user.id,
    }
    delete req.id;
    delete req.description_de;
    delete req.description_fr;
    delete req.description_it;
    delete req.user_can_change;
    delete req.employee_can_change;
    delete req.is_bvg;
    const res = await db.from('salary_monthly_items')
      .insert(req);

    if (res.error) {
      console.log(res.error);
    }

    return res;
  }

  const onSaveHandler = async () => {
    let currentSalaryId = salaryId;

    if (!currentSalaryId) {
      const res2 = await db.from('salary_monthly')
        .insert({
          users_id: props.session.user.id,
          employees_id: employee.id,
          number: 0,
          date: date,
          month: month,
          year: year,
          description: '',
          ahv: ahv,
          qs: qs,
          bvg: bvg,
          salary_description: salaryDescription,
        })
        .select()

      if (res2.error) {
        console.log(res2.error);
        return
      }

      if (res2.data && res2.data.length) {
        currentSalaryId = res2.data[0].id;
        setSalaryId(currentSalaryId);
      }
    } else {
      const res2 = await db.from('salary_monthly')
        .update({
          users_id: props.session.user.id,
          employees_id: employee.id,
          number: 0,
          date: date,
          month: month,
          year: year,
          description: '',
          salary_description: salaryDescription,
        })
        .eq('id', currentSalaryId);

      if (res2.error) {
        console.log(res2.error);
        return
      }
    }

    const res3 = await db.from('salary_monthly_items')
      .delete()
      .eq('salary_monthly_id', currentSalaryId);

    if (res3.error) {
      console.log(res3.error);
      return
    }

    for (let el of salary) {
      await saveSalaryItem({
        ...el,
        salary_monthly_id: currentSalaryId,
        group: 'salary',
      });
    }

    for (let el of taxes) {
      await saveSalaryItem({
        ...el,
        salary_monthly_id: currentSalaryId,
        group: 'tax',
      });
    }

    for (let el of other) {
      await saveSalaryItem({
        ...el,
        salary_monthly_id: currentSalaryId,
        group: 'other',
      });
    }

    setSuccess('Changes saved!');
  }

  const onPreviewHandler = async () => {
    const res = await db.from('companies')
      .select()
      .eq('users_id', props.session.user.id);

    const Comp = createMonthlyPdf({
      salary: salary,
      taxes: taxes,
      other: other,
      salaryTotal,
      taxesTotal,
      otherTotal,
      company: res.data[0],
      employee: employee,
      month: month,
      year: year,
      salary_description: salaryDescription,
      date: date,
    });

    setPdf(Comp);
  }

  const calculate = (prevSalary, prevTaxes, prevOther = other) => {
    let updatedSalary = [...prevSalary]
    let updatedTaxes = [...prevTaxes]
    let updatedOther = [...prevOther]

    let newTotalSalary = 0;
    let newTotalTaxes = 0;
    let newTotalOther = 0;
    let taxesBase = 0;
    let sBase = 0;
    let qsBase = 0;
    let hBase = 0;
    let hTotalNumber = 0;
    let vBase = 0;
    let ahvIgnoreBase = 0;
    let nbuIgnoreBase = 0;
    let otherIgnoreBase = 0;

    updatedSalary = updatedSalary.map(el => {
      const updatedEl = {
        ...el,
      }

      if (updatedEl.type === 'h' || updatedEl.type === 'hd') {
        hTotalNumber += formatedToNumber(updatedEl.value);
        updatedEl.total = properFormat(formatedToNumber(updatedEl.value) * formatedToNumber(updatedEl.base));
      }

      if (updatedEl.type === 'p') {
        if (updatedEl.category === 'qs') {
          updatedEl.base = properFormat(qsBase);
        } else if (updatedEl.base_type === 'salary_h') {
          updatedEl.base = properFormat(hBase * formatedToNumber(updatedEl.value) / 100);
        } else if (updatedEl.base_type === 'salary_v') {
          updatedEl.base = properFormat(vBase);
        } else if (updatedEl.base_type === 'salary_h_v') {
          updatedEl.base = properFormat(hBase * hTotalNumber + vBase);
        } else {
          updatedEl.base = properFormat(sBase);
        }

        updatedEl.total = properFormat(formatedToNumber(updatedEl.base) * formatedToNumber(updatedEl.value) / 100);

        if (updatedEl.base_type === 'salary_h') {
          updatedEl.total = properFormat(hBase * hTotalNumber * formatedToNumber(updatedEl.value) / 100);
        }
      }

      if (updatedEl.qs_base) {
        qsBase += formatedToNumber(updatedEl.total);
      }

      if (updatedEl.type === 'hd') {
        hBase += formatedToNumber(updatedEl.base);
      } else if (updatedEl.fix_salary) {
        vBase += formatedToNumber(updatedEl.total);
      }
      sBase += formatedToNumber(updatedEl.total);

      if (updatedEl.not_ahv) {
        ahvIgnoreBase += formatedToNumber(updatedEl.total);
      }
      if (updatedEl.not_nbu) {
        nbuIgnoreBase += formatedToNumber(updatedEl.total);
      }
      if (updatedEl.not_other) {
        otherIgnoreBase += formatedToNumber(updatedEl.total);
      }

      newTotalSalary += formatedToNumber(updatedEl.total);

      return updatedEl;
    });

    taxesBase = properFormat(taxesBase);
    updatedTaxes = updatedTaxes.map(el => {
      const updatedEl = {
        ...el,
      }

      if (updatedEl.type === 'p') {
        if (updatedEl.category === 'qs') {
          updatedEl.base = properFormat(qsBase);
        } else if (updatedEl.base_type === 'salary_h') {
          updatedEl.base = properFormat(hBase * hTotalNumber);
        } else if (updatedEl.base_type === 'salary_v') {
          updatedEl.base = properFormat(vBase);
        } else if (updatedEl.base_type === 'salary_h_v') {
          updatedEl.base = properFormat(hBase * hTotalNumber + vBase);
        } else {
          updatedEl.base = properFormat(sBase);
        }
        if (el.category === 'ahv') {
          updatedEl.base = properFormat(formatedToNumber(updatedEl.base) - ahvIgnoreBase);
        }
        if (el.category === 'nbu') {
          updatedEl.base = properFormat(formatedToNumber(updatedEl.base) - nbuIgnoreBase);
        }
        if (el.category === 'other') {
          updatedEl.base = properFormat(formatedToNumber(updatedEl.base) - otherIgnoreBase);
        }
        updatedEl.total = properFormat(formatedToNumber(updatedEl.base) * formatedToNumber(updatedEl.value) / 100);
      }

      if (updatedEl.category === 'qs') {
        updatedEl.total = properFormat(-formatedToNumber(updatedEl.total));
      }

      if (updatedEl.qs_base) {
        qsBase += formatedToNumber(updatedEl.total);
      }

      newTotalTaxes += formatedToNumber(updatedEl.total);

      return updatedEl;
    });

    updatedOther = updatedOther.map(el => {
      const updatedEl = {
        ...el,
      }

      if (updatedEl.type === 'p') {
        if (updatedEl.category === 'qs') {
          updatedEl.base = properFormat(qsBase);
        } else if (updatedEl.base_type === 'salary_h') {
          updatedEl.base = properFormat(hBase * hTotalNumber);
        } else if (updatedEl.base_type === 'salary_v') {
          updatedEl.base = properFormat(vBase);
        } else if (updatedEl.base_type === 'salary_h_v') {
          updatedEl.base = properFormat(hBase * hTotalNumber + vBase);
        } else {
          updatedEl.base = properFormat(sBase);
        }
        updatedEl.total = properFormat(formatedToNumber(updatedEl.base) * formatedToNumber(updatedEl.value) / 100);
      }

      if (updatedEl.category === 'qs') {
        updatedEl.total = properFormat(-formatedToNumber(updatedEl.total));
      }

      if (updatedEl.qs_base) {
        qsBase += formatedToNumber(updatedEl.total);
      }

      newTotalOther += formatedToNumber(updatedEl.total);

      return updatedEl;
    });

    setSalary(updatedSalary);
    setTaxes(updatedTaxes);
    setOther(updatedOther);
    setSalaryTotal(properFormat(newTotalSalary));
    setTaxesTotal(properFormat(newTotalTaxes));
    setOtherTotal(properFormat(newTotalOther));
    setTotal(properFormat(newTotalSalary - newTotalTaxes + newTotalOther));
  }

  const onSalaryChangeHandler = (index, key, value) => {
    const updatedArr = [...salary];

    let updatedItem;

    if (key === '[obj]') {
      updatedItem = {
        ...salary[index],
        ...value,
      }
    } else {
      updatedItem = {
        ...salary[index],
        [key]: value,
      }
    }

    updatedArr[index] = updatedItem;

    if (updatedItem.type === 'hd') {
      for (const el of salaryItems) {
        if (el.base_type === 'salary_h') {
          const exists = updatedArr.find(e => e.journal_code === el.journal_code)
          if (!exists) {
            updatedArr.push({
              ...el,
            });
          }
        }
      }
    }

    calculate(updatedArr, taxes);
  }

  const onSalaryAddHandler = () => {
    setSalary(prev => {
      return [
        ...prev,
        {
          description: '',
          journal_code: '0',
          type: 'p',
          value: 0,
          base: 0,
          total: 0,
          has_description: false,
          qs_base: false,
          description_add: '',
          base_type: 'salary',
          category: 'optional',
          fix_salary: false,
          not_ahv: false,
          not_nbu: false,
          not_other: false,
        },
      ]
    });
  }

  const onSalaryRemoveHandler = (index) => {
    const isHourlySalary = salary[index].type === 'hd';

    setSalary(prev => {
      return prev.filter((el, idx) => idx !== index && !(isHourlySalary && el.base_type === 'salary_h'));
    });
  }

  const onTaxesChangeHandler = (index, key, value) => {
    const updatedArr = [...taxes];

    let updatedItem;

    if (key === '[obj]') {
      updatedItem = {
        ...taxes[index],
        ...value,
      }
    } else {
      updatedItem = {
        ...taxes[index],
        [key]: value,
      }
    }

    updatedArr[index] = updatedItem;

    calculate(salary, updatedArr);
  }

  const onTaxesAddHandler = () => {
    setTaxes(prev => {
      return [
        ...prev,
        {
          description: '',
          journal_code: '0',
          type: 'p',
          value: 0,
          base: 0,
          total: 0,
          has_description: false,
          qs_base: false,
          description_add: '',
          base_type: 'salary',
          category: 'optional',
          fix_salary: false,
          not_ahv: false,
          not_nbu: false,
          not_other: false,
        },
      ]
    });
  }

  const onTaxRemoveHandler = (index) => {
    setTaxes(prev => {
      return prev.filter((_, idx) => idx !== index);
    });
  }

  const onOtherChangeHandler = (index, key, value) => {
    const updatedArr = [...other];

    let updatedItem;

    if (key === '[obj]') {
      updatedItem = {
        ...other[index],
        ...value,
      }
    } else {
      updatedItem = {
        ...other[index],
        [key]: value,
      }
    }

    updatedArr[index] = updatedItem;

    calculate(salary, taxes, updatedArr);
  }
  const onOtherAddHandler = () => {
    setOther(prev => {
      return [
        ...prev,
        {
          description: '',
          journal_code: '0',
          type: 'v',
          value: 0,
          base: 0,
          total: 0,
          has_description: false,
          qs_base: false,
          description_add: '',
          base_type: 'salary',
          category: 'optional',
          fix_salary: false,
          not_ahv: false,
          not_nbu: false,
          not_other: false,
        },
      ]
    });
  }

  const onOtherRemoveHandler = (index) => {
    setOther(prev => {
      return prev.filter((_, idx) => idx !== index);
    });
  }

  const swapItems = (arr, idx, diff) => {
    if (idx < 0 || idx >= arr.length || idx + diff < 0 || idx + diff >= arr.length) {
      return arr;
    }

    const updatedArr = [...arr];

    const tmp = updatedArr[idx];
    updatedArr[idx] = updatedArr[idx + diff];
    updatedArr[idx + diff] = tmp;

    return updatedArr;
  }

  const moveUp = (type, idx) => {
    if (type === 'salary') {
      setSalary(curr => swapItems(curr, idx, -1));
    } else if (type === 'taxes') {
      setTaxes(curr => swapItems(curr, idx, -1));
    } else if (type === 'other') {
      setOther(curr => swapItems(curr, idx, -1));
    }
  }

  const moveDown = (type, idx) => {
    if (type === 'salary') {
      setSalary(curr => swapItems(curr, idx, 1));
    } else if (type === 'taxes') {
      setTaxes(curr => swapItems(curr, idx, 1));
    } else if (type === 'other') {
      setOther(curr => swapItems(curr, idx, 1));
    }
  }

  const onErrorHandler = (err) => {
    setError(err);
  }

  const salaryOptions = salaryItems.filter(el => el.group === 'salary').map(el => {
    return {
      ...el,
      label: el.description_de,
    }
  });

  const salaryComp = salary.map((el, idx) => {
    return (
      <React.Fragment key={idx}>
        <Grid item xs={el.has_description ? 5 : 10} sm={el.has_description ? 5 : 10}>
          <Autocomplete
            disablePortal
            id="title"
            options={salaryOptions}
            variant="standard"
            value={salaryOptions.find(opt => opt.journal_code === el.journal_code) || ' '}
            isOptionEqualToValue={(opt, val) => opt?.journal_code === val?.journal_code}
            onChange={(_, val) => {
              if (!val) {
                return;
              }
              const obj = {
                description: val.description_de,
                journal_code: val.journal_code,
                type: val.type ? val.type : 'v',
                value: properFormat(val.value, 3),
                base: properFormat(val.base),
                total: properFormat(val.total),
                has_description: val.has_description,
                qs_base: val.qs_base,
                description_add: '',
                base_type: val.base_type,
                fix_salary: val.fix_salary,
                category: val.category,
                not_ahv: val.not_ahv,
                not_nbu: val.not_nbu,
                not_other: val.not_other,
              }
              onSalaryChangeHandler(idx, '[obj]', obj);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" label={translate('Naziv')} />}
          />
        </Grid>
        {
          el.has_description ?
            <Grid item xs={5} sm={5}>
              <TextField
                fullWidth
                label={translate("Opis")}
                variant="standard"
                value={el.description_add}
                onChange={e => onSalaryChangeHandler(idx, 'description_add', e.target.value)} />
            </Grid>
            : null
        }
        <Grid item xs={2} sm={2} style={{ textAlign: 'right' }}>
          <IconButton color="primary" onClick={() => moveUp('salary', idx)}>
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => moveDown('salary', idx)}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Grid>
        <Grid item xs={el.type !== 'v' ? 6 : 0} sm={4}>
          {el.type !== 'v' && el.type !== 'vn' ?
            <TextField
              fullWidth
              label={translate("Vrednost")}
              variant="standard"
              value={el.value}
              onChange={e => onSalaryChangeHandler(idx, 'value', e.target.value)} />
            : null}
        </Grid>
        <Grid item xs={el.type !== 'v' ? 6 : 0} sm={4}>
          {el.type !== 'v' && el.type !== 'vn' ?
            <TextField
              fullWidth
              label={translate("Osnovica")}
              variant="standard"
              value={el.base}
              onChange={e => onSalaryChangeHandler(idx, 'base', e.target.value)} />
            : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label={translate("Ukupno")}
            variant="standard"
            value={el.total}
            onChange={e => onSalaryChangeHandler(idx, 'total', e.target.value)} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <IconButton color="error" onClick={() => onSalaryRemoveHandler(idx)}><DeleteIcon /></IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </React.Fragment >
    );
  });

  const salaryCard = (
    <CCard title="Plata">
      <Grid container spacing={2}>
        {salaryComp}
        <Grid item xs={6} sm={9}>
        </Grid>
        <Grid item xs={3} sm={1}>
          <b>Total:</b>
        </Grid>
        <Grid item xs={3} sm={2}>
          {salaryTotal}
        </Grid>
        <Grid item xs={12}>
          <IconButton color="primary" onClick={onSalaryAddHandler}><AddIcon /></IconButton>
        </Grid>
      </Grid>
    </CCard>
  );

  const taxesOptions = salaryItems.filter(el => el.group === 'tax').map(el => {
    return {
      ...el,
      label: el.description_de,
    }
  });

  const taxesComp = taxes.map((el, idx) => {
    return (
      <React.Fragment key={idx}>
        <Grid item xs={el.has_description ? 5 : 10} sm={el.has_description ? 5 : 10}>
          <Autocomplete
            disablePortal
            id="title"
            options={taxesOptions}
            variant="standard"
            value={taxesOptions.find(opt => opt.journal_code === el.journal_code) || ' '}
            isOptionEqualToValue={(opt, val) => opt?.journal_code === val?.journal_code}
            onChange={(_, val) => {
              if (!val) {
                return;
              }
              const obj = {
                description: val.description_de,
                journal_code: val.journal_code,
                type: val.type ? val.type : 'v',
                value: properFormat(val.value, 3),
                base: properFormat(val.base),
                total: properFormat(val.total),
                has_description: val.has_description,
                qs_base: val.qs_base,
                description_add: '',
                base_type: val.base_type,
                category: val.category,
              }
              onTaxesChangeHandler(idx, '[obj]', obj);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" label={translate('Naziv')} />}
          />
        </Grid>
        {
          el.has_description ?
            <Grid item xs={5} sm={5}>
              <TextField
                fullWidth
                label={translate("Opis")}
                variant="standard"
                value={el.description_add}
                onChange={e => onTaxesChangeHandler(idx, 'description_add', e.target.value)} />
            </Grid>
            : null
        }
        <Grid item xs={2} sm={2} style={{ textAlign: 'right' }}>
          <IconButton color="primary" onClick={() => moveUp('taxes', idx)}>
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => moveDown('taxes', idx)}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Grid>
        <Grid item xs={el.type !== 'v' ? 6 : 0} sm={4}>
          {el.type !== 'v' ?
            <TextField
              fullWidth
              label={translate("Vrednost")}
              variant="standard"
              value={el.value}
              onChange={e => onTaxesChangeHandler(idx, 'value', e.target.value)} />
            : null}
        </Grid>
        <Grid item xs={el.type !== 'v' ? 6 : 0} sm={4}>
          {el.type !== 'v' ?
            <TextField
              fullWidth
              label={translate("Osnovica")}
              variant="standard"
              value={el.base}
              onChange={e => onTaxesChangeHandler(idx, 'base', e.target.value)} />
            : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label={translate("Ukupno")}
            variant="standard"
            value={el.total}
            onChange={e => onTaxesChangeHandler(idx, 'total', e.target.value)} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <IconButton color="error" onClick={() => onTaxRemoveHandler(idx)}><DeleteIcon /></IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </React.Fragment >
    );
  });

  const taxesCard = (
    <CCard title="Odbici">
      <Grid container spacing={2}>
        {taxesComp}
        <Grid item xs={6} sm={9}>
        </Grid>
        <Grid item xs={3} sm={1}>
          <b>Total:</b>
        </Grid>
        <Grid item xs={3} sm={2}>
          -{taxesTotal}
        </Grid>
        <Grid item xs={12}>
          <IconButton color="primary" onClick={onTaxesAddHandler}><AddIcon /></IconButton>
        </Grid>
      </Grid>
    </CCard>
  );

  const otherOptions = salaryItems.filter(el => el.group === 'other').map(el => {
    return {
      ...el,
      label: el.description_de,
    }
  });

  const otherComp = other.map((el, idx) => {
    return (
      <React.Fragment key={idx}>
        <Grid item xs={el.has_description ? 5 : 10} sm={el.has_description ? 5 : 10}>
          <Autocomplete
            disablePortal
            id="title"
            options={otherOptions}
            variant="standard"
            value={otherOptions.find(opt => opt.journal_code === el.journal_code) || ' '}
            isOptionEqualToValue={(opt, val) => opt?.journal_code === val?.journal_code}
            onChange={(_, val) => {
              if (!val) {
                return;
              }
              const obj = {
                description: val.description_de,
                journal_code: val.journal_code,
                type: val.type ? val.type : 'v',
                value: properFormat(val.value, 3),
                base: properFormat(val.base),
                total: properFormat(val.total),
                has_description: val.has_description,
                qs_base: val.qs_base,
                description_add: '',
                base_type: val.base_type,
                category: val.category,
              }
              onOtherChangeHandler(idx, '[obj]', obj);
            }}
            renderInput={(params) => <TextField {...params} variant="standard" label={translate('Naziv')} />}
          />
        </Grid>
        {
          el.has_description ?
            <Grid item xs={5} sm={5}>
              <TextField
                fullWidth
                label={translate("Opis")}
                variant="standard"
                value={el.description_add}
                onChange={e => onOtherChangeHandler(idx, 'description_add', e.target.value)} />
            </Grid>
            : null
        }
        <Grid item xs={2} sm={2} style={{ textAlign: 'right' }}>
          <IconButton color="primary" onClick={() => moveUp('other', idx)}>
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => moveDown('other', idx)}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </Grid>
        <Grid item xs={el.type !== 'v' ? 6 : 0} sm={4}>
          {el.type !== 'v' ?
            <TextField
              fullWidth
              label={translate("Vrednost")}
              variant="standard"
              value={el.value}
              onChange={e => onOtherChangeHandler(idx, 'value', e.target.value)} />
            : null}
        </Grid>
        <Grid item xs={el.type !== 'v' ? 6 : 0} sm={4}>
          {el.type !== 'v' ?
            <TextField
              fullWidth
              label={translate("Osnovica")}
              variant="standard"
              value={el.base}
              onChange={e => onOtherChangeHandler(idx, 'base', e.target.value)} />
            : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label={translate("Ukupno")}
            variant="standard"
            value={el.total}
            onChange={e => onOtherChangeHandler(idx, 'total', e.target.value)} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <IconButton color="error" onClick={() => onOtherRemoveHandler(idx)}><DeleteIcon /></IconButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </React.Fragment >
    );
  });

  const otherCard = (
    <CCard title="Plata ostalo">
      <Grid container spacing={2}>
        {otherComp}
        <Grid item xs={6} sm={9}>
        </Grid>
        <Grid item xs={3} sm={1}>
          <b>Total:</b>
        </Grid>
        <Grid item xs={3} sm={2}>
          {otherTotal}
        </Grid>
        <Grid item xs={12}>
          <IconButton color="primary" onClick={onOtherAddHandler}><AddIcon /></IconButton>
        </Grid>
      </Grid>
    </CCard>
  );

  return (
    <Layout title="Mesecni obracun">
      <CCard title={employee.name ? employee.name + ' ' + employee.surname + ' ' + employee.ahv_number : null} back={() => navigate(-1)} notranslate>

        <FormControl variant="standard" fullWidth>
          <InputLabel id={'month-label'}><Translate>Mesec</Translate></InputLabel>
          <Select
            fullWidth
            labelId={'month-label'}
            variant="standard"
            value={month}
            onChange={e => setMonth(e.target.value)}>
            <MenuItem value="1"><Translate>Januar</Translate></MenuItem>
            <MenuItem value="2"><Translate>Februar</Translate></MenuItem>
            <MenuItem value="3"><Translate>Mart</Translate></MenuItem>
            <MenuItem value="4"><Translate>April</Translate></MenuItem>
            <MenuItem value="5"><Translate>Maj</Translate></MenuItem>
            <MenuItem value="6"><Translate>Jun</Translate></MenuItem>
            <MenuItem value="7"><Translate>Jul</Translate></MenuItem>
            <MenuItem value="8"><Translate>Avgust</Translate></MenuItem>
            <MenuItem value="9"><Translate>Septembar</Translate></MenuItem>
            <MenuItem value="10"><Translate>Oktobar</Translate></MenuItem>
            <MenuItem value="11"><Translate>Novembar</Translate></MenuItem>
            <MenuItem value="12"><Translate>Decembar</Translate></MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel id={'year-label'}><Translate>Godina</Translate></InputLabel>
          <Select
            fullWidth
            labelId={'year-label'}
            variant="standard"
            value={year}
            onChange={e => setYear(e.target.value)}>
            {
              Array.from(Array((new Date().getFullYear()) - 2021).keys()).map((_, idx) => {
                const year = new Date().getFullYear() - idx;
                return (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
        <FormGroup fullWidth style={{ marginTop: 15, marginBototm: 15, }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} variant="standard">
            <DatePicker
              label={translate('Datum')}
              value={date}
              variant="standard"
              format="DD.MM.YYYY."
              onChange={newValue => setDate(newValue)} />
          </LocalizationProvider>
        </FormGroup>
        <FormGroup fullWidth>
          <TextField
            fullWidth
            label={translate('Plata opis')}
            variant="standard"
            value={salaryDescription}
            onChange={e => setSalaryDescription(e.target.value)}
            multiline
            minRows={3} />
        </FormGroup>

        <MessageDialog
          open={!!error}
          title={translate('Greška')}
          text={error}
          onClose={() => setError(null)} />

      </CCard>
      <br />

      {!pdf ? salaryCard : null}
      <br />

      {!pdf ? taxesCard : null}
      <br />

      {!pdf ? otherCard : null}
      <br />

      {
        !pdf ?
          <CCard>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={9}>
                <Button variant="contained" onClick={onSaveHandler}><Translate>Save</Translate></Button>
                &nbsp;
                <Button variant="contained" onClick={onPreviewHandler}><Translate>Preview</Translate></Button>
              </Grid>
              <Grid item xs={4} sm={3}>
                <b><Translate>Za isplatu:</Translate> {total}</b>
              </Grid>
            </Grid>
          </CCard>
          : null
      }
      <br />

      {
        pdf ?
          <CCard title="Pdf" back={() => setPdf(null)}>
            <PDFViewer style={{
              height: 200,
              width: '100%',
              height: '98vh'
            }}>
              {pdf}
            </PDFViewer>
          </CCard>
          : null
      }

      <MessageDialog
        open={!!error}
        title={translate('Greška')}
        text={error}
        onClose={() => setError(null)} />

      <MessageDialog
        open={!!success}
        title="Success"
        text={success}
        onClose={() => setSuccess(null)} />

    </Layout >
  );
}

export default withStore(['nav'])(MonthlyCalc);
