import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  IconButton,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { withStore } from '../store';
import { db } from '../db';
import { translate } from '../utils/translate';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericTable from '../components/GenericTable';
import ConfirmDialog from '../components/ConfirmDialog';
import Translate from '../components/Translate';

const columns = [
  {
    id: 'department',
    title: translate('Odeljenje'),
  },
  {
    id: 'actions',
    title: '',
  },
]

const Departments = (props) => {
  const navigate = useNavigate();

  const [removeId, setRemoveId] = useState(null);

  const [values, setValues] = useState([]);

  const fetchData = async () => {
    const res = await db.from('departments')
      .select()
      .eq('users_id', props.session.user.id);

    if (res.error) {
      console.log(res.error);
      return;
    }

    setValues(res.data);
  }

  const deleteData = async (id) => {
    const res = await db.from('departments')
      .delete()
      .eq('id', id);

    if (res.error) {
      console.log(res.error);
    }

    setValues(values.filter(el => el.id !== id));
  }

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));
  }, []);

  const onEditHandler = (id) => {
    navigate('/departments/' + id);
  }

  const onDeleteHandler = (id) => {
    deleteData(removeId)
      .catch(err => console.log(err));
    setRemoveId(null);
  }

  let removeDialog = null;
  if (removeId) {
    removeDialog = (
      <ConfirmDialog
        title={translate('Brisanje odeljenja')}
        text={translate('Da li ste sigurni da želite obrisati odeljenje i sve radnike u njemu')}
        open={!!removeId}
        onConfirm={onDeleteHandler}
        onCancel={() => setRemoveId(null)}
        payload={removeId}
        danger
      />
    );
  }

  const data = values.map(el => {
    const res = {
      ...el,
      actions: (
        <>
          <IconButton onClick={() => onEditHandler(el.id)}>
            <EditIcon />
          </IconButton>
          &nbsp;
          <IconButton color="error" onClick={() => setRemoveId(el.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    };

    return res;
  });

  return (
    <Layout title="Odeljenja">
      <CCard title=" " back={() => navigate(-1)}>
        <Button variant="contained" onClick={() => onEditHandler('new')}><Translate>Novo odeljenje</Translate></Button>
        <br />
        <br />
        <GenericTable
          columns={columns}
          data={data} />
      </CCard>

      {removeDialog}
    </Layout>
  );
}

export default withStore(['nav'])(Departments);
