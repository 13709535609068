import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { withStore } from '../store';
import { db } from '../db';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericForm from '../components/GenericForm';
import MessageDialog from '../components/MessageDialog';

const initialFrm = [
  {
    id: 'department',
    type: 'text',
    label: 'Odeljenje',
    value: '',
    valication: {
      required: true,
    },
  },
]

const DepartmentEdit = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const isNew = id ? false : true;

  const [frm, setFrm] = useState(initialFrm);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const res = await db.from('departments')
      .select()
      .eq('id', id);

    if (res.error || !res.data || !res.data.length) {
      console.log(res.error);
      navigate('/departments');
      return;
    }

    if (res.data && res.data.length) {
      const updatedFrm = frm.map(el => ({
        ...el,
        value: res.data[0][el.id],
      }));
      setFrm(updatedFrm);
    }
  }

  useEffect(() => {
    if (id) {
      fetchData().catch(err => console.log(err));
    }
  }, [id]);

  const onSaveHandler = async (data) => {
    const req = data.reduce((acc, cur) => {
      acc[cur.id] = cur.value;
      return acc;
    }, {});

    req.users_id = props.session.user.id;

    if (isNew) {
      const res = await db.from('departments')
        .insert(req);

      if (res.error) {
        console.log(res.error);
        setError(res.error.message);
        return;
      }
    } else {
      const res = await db.from('departments')
        .update(req)
        .eq('id', id);
      if (res.error) {
        console.log(res.error);
        setError(res.error.message);
        return;
      }
    }

    navigate('/departments');
  }

  const onErrorHandler = (err) => {
    setError(err);
  }

  return (
    <Layout title="Odeljenja">
      <CCard title=" " back={() => navigate(-1)}>
        <GenericForm
          data={frm}
          onSave={onSaveHandler}
          onError={onErrorHandler} />

        <MessageDialog
          open={!!error}
          title="Greška"
          text={error}
          onClose={() => setError(null)} />
      </CCard>
    </Layout>
  );
}

export default withStore(['nav'])(DepartmentEdit);
