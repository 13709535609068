import React from 'react';
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from '@mui/material';

import { translate } from '../utils/translate';

const MainMenuIcon = (props) => {
  return (
    <Card onClick={() => props.onClick(props.link)}>
      <CardActionArea style={{ height: 0, paddingTop: '20%', paddingBottom: '80%' }}>
        <CardContent sx={{ textAlign: 'center' }}>
          {React.cloneElement(props.icon, { color: 'primary', sx: { fontSize: 50 } })}
          <br />
          <Typography varian="h5" component="h5">{translate(props.title)}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default MainMenuIcon;
