import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
} from '@mui/material';

import { withStore } from '../store';
import { db } from '../db';
import { translate } from '../utils/translate';
import Translate from '../components/Translate';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericTable from '../components/GenericTable';
import { createMonthlyPdf } from '../pdf/MonthlyPdf';
import { PDFViewer } from '@react-pdf/renderer';

const months = [
  'Januar',
  'Februar',
  'Mart',
  'April',
  'Maj',
  'Jun',
  'Jul',
  'Avgust',
  'Septembar',
  'Oktobar',
  'Novembar',
  'Decembar',
]

const isError = (r) => {
  if (!r) {
    return true;
  }

  if (r.error) {
    return true;
  }

  if (!r.data || !r.data.length) {
    return true;
  }

  return false;
}

const columns = [
  {
    id: 'year',
    title: translate('Godina'),
  },
  {
    id: 'month',
    title: translate('Mesec'),
  },
  {
    id: 'actions',
    title: '',
  },
]

const SalaryHistory = (props) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [values, setValues] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [company, setCompany] = useState(null);

  const [pdf, setPdf] = useState(null);

  const fetchData = async () => {
    const res1 = await db.from('companies')
      .select()
      .eq('users_id', props.session.user.id);

    if (isError(res1)) {
      return;
    }

    setCompany(res1.data[0]);

    const res2 = await db.from('employees')
      .select()
      .eq('id', id);

    if (isError(res2)) {
      return;
    }

    setEmployee(res2.data[0]);

    const res = await db.from('salary_monthly')
      .select()
      .eq('employees_id', id);

    if (isError(res)) {
      return;
    }

    setValues(res.data);

  }

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));
  }, [id]);

  const onPreviewClicked = async (id) => {
    const resSalary = await db.from('salary_monthly')
      .select()
      .eq('id', id);
    if (isError(resSalary)) {
      return;
    }

    const resItems = await db.from('salary_monthly_items')
      .select()
      .eq('salary_monthly_id', id);
    if (isError(resItems)) {
      return;
    }

    const salary = [];
    const taxes = [];
    const other = [];

    for (const el of resItems.data) {
      if (el.group === 'salary') {
        salary.push({
          ...el
        });
      } else if (el.group === 'tax') {
        taxes.push({
          ...el
        });
      } else if (el.group === 'other') {
        other.push({
          ...el
        });
      }
    }

    let newTotalSalary = 0;
    let newTotalTaxes = 0;
    let newTotalOther = 0;

    salary.forEach(el => {
      newTotalSalary += +el.total;
    });

    taxes.forEach(el => {
      newTotalTaxes += +el.total;
    });

    other.map(el => {
      newTotalOther += +el.total;
    });

    const Comp = createMonthlyPdf({
      salary: salary,
      taxes: taxes,
      other: other,
      salaryTotal: newTotalSalary,
      taxesTotal: newTotalTaxes,
      otherTotal: newTotalOther,
      company: company,
      employee: employee,
      month: resSalary.data[0].month,
      year: resSalary.data[0].year,
      salary_description: resSalary.data[0].salary_description,
    });

    setPdf(Comp);
  }

  const onEditClicked = (employeeId, year, month) => {
    navigate(`/salary/monthly/${employeeId}?year=${year}&month=${month}`);
  }

  const data = values.map(el => {
    const res = {
      ...el,
      month: translate(months[el.month - 1]),
      actions: (
        <>
          <Button onClick={() => onPreviewClicked(el.id)}>
            <Translate>Pregledaj</Translate>
          </Button>
          &nbsp;
          <Button onClick={() => onEditClicked(el.employees_id, el.year, el.month)}>
            <Translate>Izmeni</Translate>
          </Button>
        </>
      ),
    };

    return res;
  });

  let content = (
    <GenericTable
      columns={columns}
      data={data} />
  );

  if (pdf) {
    content = (
      <PDFViewer style={{
        height: 200,
        width: '100%',
        height: '98vh'
      }}>
        {pdf}
      </PDFViewer>
    );
  }

  let title = ' ';
  if (employee) {
    title = employee.name + ' ' + employee.surname + ' ' + employee.ahv_number;
  }

  return (
    <Layout title="Istorija obracuna">
      <CCard title={title} back={() => pdf ? setPdf(null) : navigate(-1)} notranslate>
        {content}
      </CCard>
    </Layout>
  );
}

export default withStore(['nav'])(SalaryHistory);
