import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

import { withStore } from '../store';
import { db } from '../db';
import { translate } from '../utils/translate';
import { exportJournal } from '../utils/excel';
import Layout from '../hoc/Layout';
import CCard from '../hoc/Card';
import GenericTable from '../components/GenericTable';
import Translate from '../components/Translate';

const columns = [
  {
    id: 'name',
    title: translate('Ime'),
  },
  {
    id: 'surname',
    title: translate('Prezime'),
  },
  {
    id: 'actions',
    title: translate(''),
  },
]

const JournalEmployees = (props) => {
  const navigate = useNavigate();

  const [values, setValues] = useState([]);

  const [departmentId, setDepartmentId] = useState('all');
  const [departments, setDepartments] = useState([]);

  const [company, setCompany] = useState({});

  const [year, setYear] = useState(new Date().getFullYear());

  const fetchDepartments = async () => {
    const res = await db.from('departments')
      .select();

    if (res.error) {
      console.log(res.error);
      return;
    }

    setDepartments(res.data);
  }

  const fetchData = async () => {
    const res = await db.from('employees')
      .select()
      .eq('users_id', props.session.user.id)
      .is('employment_to', null)
      .order('surname')
      .order('name');

    if (res.error) {
      console.log(res.error);
      return;
    }

    setValues(res.data);

    const res1 = await db.from('companies')
      .select()
      .eq('users_id', props.session.user.id);

    if (res1.error || !res1.data || !res1.data.length) {
      console.log(res.error);
      return;
    }

    setCompany(res1.data[0]);
  }

  useEffect(() => {
    fetchData()
      .catch(err => console.log(err));

    fetchDepartments()
      .catch(err => console.log(err));
  }, []);

  const onHistoryClicked = async (id) => {
    const employee = values.find(el => el.id === id);
    if (!employee) {
      return;
    }

    navigate(`/salary/history/${id}?year=${year}`);
  }

  const onJournalClicked = async (id) => {
    const employee = values.find(el => el.id === id);
    if (!employee) {
      return;
    }

    const res = await db.from('salary_monthly')
      .select('*, salary_monthly_items(*)')
      .eq('employees_id', id)
      .eq('year', year);

    if (res.error || !res.data || !res.data.length) {
      console.log(res.error);
      return;
    }

    const salaries = res.data;

    exportJournal(company, employee, salaries);
  }

  const departmentOptions = departments.map(el => {
    return (
      <MenuItem value={el.id}>{el.department}</MenuItem>
    );
  });

  const data = values
    .filter(el => departmentId === 'all' || el.department === departmentId)
    .map(el => {
      const res = {
        ...el,
        actions: (
          <>
            <Button onClick={() => onHistoryClicked(el.id)}>
              <Translate>Istorija obracuna</Translate>
            </Button>
            &nbsp;
            <Button onClick={() => onJournalClicked(el.id)}>
              <Translate>Zurnal</Translate>
            </Button>
          </>
        ),
      };

      return res;
    });

  return (
    <Layout title="Zurnal">
      <CCard title=" " back={() => navigate(-1)}>
        <FormControl fullWidth>
          <InputLabel id="departments-lbl"><Translate>Odeljenje</Translate></InputLabel>
          <Select
            labelId="departments-lbl"
            id="departments"
            label={translate('Odeljenje')}
            value={departmentId}
            onChange={(e) => setDepartmentId(e.target.value)}>
            <MenuItem value="all"><Translate>Sva odeljenja</Translate></MenuItem>
            {departmentOptions}
          </Select>
        </FormControl>
        <br />
        <br />
        <FormControl fullWidth>
          <InputLabel id="year-lbl"><Translate>Godina</Translate></InputLabel>
          <Select
            labelId="year-lbl"
            id="year"
            label={translate('Godina')}
            value={year}
            onChange={(e) => setYear(e.target.value)}>
            {
              Array.from(Array((new Date().getFullYear()) - 2021).keys()).map((_, idx) => {
                const year = new Date().getFullYear() - idx;
                return (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
        <br />
        <br />
        <GenericTable
          columns={columns}
          data={data} />
      </CCard>
    </Layout>
  );
}

export default withStore(['nav'])(JournalEmployees);
