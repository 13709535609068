export const SUPABASE_URL = 'https://meywpmndoabulvwzmaou.supabase.co';
export const SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1leXdwbW5kb2FidWx2d3ptYW91Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODI2NTMyNjEsImV4cCI6MTk5ODIyOTI2MX0.6fbPKVVM2UJq3svcdSbi-kEsikYgiryu-hBgaTQ1r_w';

export const LOGO_BASE_URL = 'https://meywpmndoabulvwzmaou.supabase.co/storage/v1/object/public/logo';

export const JOURNAL_TEMPLATE = 'https://ch-salary.ch/journal.xlsx';
//export const JOURNAL_TEMPLATE = 'http://localhost:3000/journal.xlsx';

export const XLSX_CONVER_API = 'https://xlsxpdf.tehne-studio.com/api/convert';
//export const XLSX_CONVER_API = 'http://localhost:8080/api/convert';
