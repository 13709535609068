import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { withStore } from '../store';
import { db } from '../db';
import Layout, { createMenuItems } from '../hoc/Layout';
import CCard from '../hoc/Card';

import MainMenuIcon from '../components/MainMenuIcon';
import { LOGO_BASE_URL } from '../constants';

const Home = (props) => {
  const navigate = useNavigate();

  const [company, setCompany] = useState({});

  const fetchData = async () => {
    const id = props.session.user.id;

    const res = await db.from('companies')
      .select()
      .eq('users_id', id);

    if (res.error) {
      console.log(res.error);
    }

    if (res.data && res.data.length) {
      setCompany(res.data[0]);
    }
  }

  useEffect(() => {
    if (props.session) {
      fetchData().catch(err => console.log(err));
    }
  }, [props.session]);

  const menuItems = createMenuItems();
  const mainMenuItems = [];
  for (let m of menuItems) {
    m.forEach(el => mainMenuItems.push(el));
  }

  const menu = mainMenuItems.map(el => {
    return (
      <Grid key={el.link} item xs={6} md={3} lg={2}>
        <MainMenuIcon title={el.title} icon={el.icon} link={el.link} onClick={(link) => navigate(link)} />
      </Grid>
    );
  })

  return (
    <Layout title="Početna">
      <CCard title={company.name ? company.name : null} notranslate>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {company.logo ? <img src={`${LOGO_BASE_URL}/${company.logo}`} style={{ maxHeight: 200 }} /> : null}
        </div>
        <br />
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {menu}
        </Grid>
      </CCard>
    </Layout>
  );
}

export default withStore(['nav'])(Home);
