import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { withStore } from '../../store';
import { db } from '../../db';

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://ch-salary.ch">
        CH Salary
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const Register = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);

    const data = new FormData(event.currentTarget);

    const lang = data.get('lang');
    const companyName = data.get('companyName');
    const vat = data.get('vat');
    const email = data.get('email');
    const password = data.get('password');
    const password2 = data.get('password2');
    const terms = data.get('terms');

    if (!password || password.length < 8) {
      setError('Lozinka mora imati najvanje 8 karaktera');
      return;
    }

    if (password !== password2) {
      setError('Lozinke nisu iste');
      return;
    }

    if (!terms) {
      setError('Morate prihvatiti uslove koricenja');
      return;
    }

    const res1 = await db.auth.signUp({
      email: email,
      password: password,
    });

    if (res1.error) {
      setError(res1.error.message);
      return;
    }

    const user = res1.data.user;

    const res2 = await db.from('profiles')
      .insert({
        id: user.id,
        role: 'admin',
        active: true,
        lang: lang,
      });

    if (res2.error) {
      setError(res2.error.message);
      return;
    }

    const res3 = await db.from('companies')
      .insert({
        users_id: user.id,
        name: companyName,
        vat: vat,
        mail: email,
      });

    if (res3.error) {
      setError(res3.error.message);
      return;
    }

    navigate('/');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              {error ? <Grid item xs={12}><Alert severity="error">{error}!</Alert></Grid> : null}
              <Grid item xs={12}>
                <Select name="lang" id="lang" defaultValue="de" fullWidth>
                  <MenuItem value="de">German</MenuItem>
                  <MenuItem value="fr">Franch</MenuItem>
                  <MenuItem value="it">Italian</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="companyName"
                  required
                  name="companyName"
                  fullWidth
                  id="companyName"
                  label="Company name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="vat"
                  label="VAT"
                  name="vat"
                  autoComplete="vat"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label="Repeat password"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox id="terms" name="terms" value="true" color="primary" />}
                  label="Prihvatam uslove koriscenja"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link onClick={() => navigate('/auth/login')} variant="body2" component="button">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

export default withStore(['nav'])(Register);
